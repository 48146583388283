import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDHb12bI8vrvSH8GTmJCDJOAs0MUurKjNo",
  authDomain: "community-7cb31.firebaseapp.com",
  projectId: "community-7cb31",
  storageBucket: "community-7cb31.appspot.com",
  messagingSenderId: "39952056666",
  appId: "1:39952056666:web:d82ff3aa4d662c26b93363",
};

firebase.initializeApp(firebaseConfig);
let auth = firebase.auth();
export { auth, firebase };
