import React from "react";
import Historyimg from "../../Assets/images/12 35.jpg";
import Leaderimg from "../../Assets/images/2134 1.png";
import "../../Assets/Css/history.css";

const Index = () => {
  return (
    <>
      <div className="member-head">
        <div className="member-head1">
          <h1>OUR PROUD HISTORY</h1>
        </div>
      </div>
      <div className="fillpage">
        <div className="fillpage1">
          <div className="educnt">
            <div className="edu-image">
              <img src={Historyimg} alt="" />
            </div>
          </div>
          <div className="eduinfo">
            <p>
              வணக்கம் ! 1995 ஆம் ஆண்டு தென்மாவட்டங்களில் இருந்து கோவைக்கு தொழில்
              நிமித்தமாக குடியேறியவர்கள் ஒன்றிணையும் பொருட்டு ஒரு சங்கம் தொடங்க
              திரு.கனகராஜ் அவர்கள், திரு, ஜனார்த்தனன் அவர்கள், திரு,திருமுடி
              அவர்கள்,திரு,நந்தகோபால் ஆவார்கள்,திரு,ராமானுஜம்
              அவர்கள்,திரு,எஸ்.மணிகண்டன் அவர்கள்,திரு,எஸ்.கே.பாண்டியன்
              அவர்கள்,திரு,எத்திராஜ் அவர்கள் மற்றும் பல முன்னோடிகள் முயற்சி
              மேற்கொண்டு 31/07/1996 ல்சங்கம் தொடங்கப்பட்டு 19/09/1996 ல்
              தலைவர்-1, உப தலைவர்-1 , செயலாளர்-1,துணைச்செயலாளர்கள்-3,
              பொருளாளர்-1, துணைப்பொருளாளர்-1, நிர்வாகக்குழுவில்-17 பேர் எனவும்,
              மொத்த உறுப்பினர்கள் 105 என சங்கம் அமைத்து "கோவை வாழ் தென்மாவட்ட
              ஒக்கலிகர் முன்னேற்ற சங்கம்" என்னும் பெயரில் முறைப்படி பதிவு
              செய்யப்பட்டு சிறப்பாக செயல் பட்டுவருகிறது. ஏழை குழந்தைகளுக்கு இலவச
              பாடப்புத்தகம்,சீருடை,வசதி குறைந்த அரசு பள்ளிகளுக்கு உதவி எனவும்
              குழந்தைகளை ஊக்கப்படுத்தும் விதமாக வருடந்தோறும் தேர்வில் வெற்றி
              பெறும் குழந்தைகளுக்கு உதவித்தொகை,கேடயம் வழங்கி ஊக்கப்படுத்தி
              வருகிறது.
            </p>
            <p>
              அக்காலகட்டத்தில் உறுப்பினராக இணைய நுழைவுக் கட்டணமாக
              100/- ம், வருடச்சந்தாவாக 50/- ம் வசூலிக்கப்பட்டது. அடுத்த
              முயற்சியாக அறக்கட்டளையும் தொடங்கப்பட்டு அதன் மூலம் தேனி மாவட்டம்
              கம்பத்தில் பொங்கல் திருநாளை முன்னிட்டு வருடந்தோறும் நீர்மோர்
              வழங்கப்பட்டு வருகிறது, பள்ளிகளுக்கு பீரோ,சேர்,இருக்கைகள்
              வழங்கப்பட்டது, மருதமலை அமர்ஜோதி தொழுநோயாளிகளுக்கு உதவிகள்
              செய்யப்பட்டது. குறைந்த கட்டண ஆம்புலன்ஸ் சேவை, மருத்துவ ஆலோசணை
              கல்வி ஆலோசணை,திருமண தகவல் பரிமாற்றம், மற்றும் மாணவர் களை
              ஊக்கப்படுத்தும் செயல்பாடுகளிலும் தீவிரம் காட்டி வருகிறது.
              மருதமலையில் நமது சங்கத்திற்காக 7 1/2 சென்ட் நிலம் வாங்கப்பட்டது.
              பின் 2013 ல் அந்த இடம் கைமாற்றம் செய்யப்பட்டு 10 சென்டாக
              வாங்கப்பட்டது.
            </p>
            <p>
              இடையில் ஒரு சில காரணங்களால் சங்க பதிவு தடைபட்ட காரணத்தால் மீண்டும்
              இச்சங்கம் "தென்மாவட்ட ஒக்கலிகர் (காப்பு) மகாஜன நல சங்கம்" என
              பெயரிட்டு 17/08/2011 ல் மீண்டும் பதிவு செய்யப்பட்டு திரு,சேகர்
              அவர்கள் தலைவராகவும்,திரு,உடையாளி அவர்கள் செயலாளராகவும்,திரு,கேசவன்
              அவர்கள் பொருளாளராகவும் மற்றும் நிர்வாகக்குழுவில் 17 பேரும் என 20
              பேர் நிர்வாகிகளாகக் கொண்டு அதே உற்சாகத்துடன் செயல்படத்தொடங்கியது.
              பின்னர் 2016 ல் திரு,குணசேகரன் அவர்கள் தலைவராகவும், 2022
              ல்திரு,வீமராஜ்அவர்கள் தலைவராகவும்,திரு,நந்தகுமார் அவர்கள்
              செயலாளராகவும்,திரு,விஜயன் அவர்கள் பொருளாளராகவும் திரு, சவரிநாதன்
              அவர்கள், திரு,சுருளிநாதன் அவர்கள் துணைத்தலைவர்
              களாகவும்,திரு,ரூபத்குமார் அவர்கள்,திரு,ராஜேஷ்கண்ணன் அவர்கள்
              இணைச்செயலாளர்களாகவும் மற்றும் 26 செயற்குழு உறுப்பினர்களுடன்
              நிர்வாகக்குழு இயங்கிவருகிறது.
            </p>
            <p>
              இதன் அடுத்த இலக்காக பல்வேறு பகுதிகளில் உள்ள நம் மக்கள் எளிதில்
              தொடர்பு கொள்ளவும்,தெரிந்து கொள்ளவும் உதவும் பொருட்டு
              தொடங்கப்பட்டது தான் www.thenmavattaokkaligarsangam.in என்ற
              இணையதளம். இதில் தங்கள் அனைவரும் தங்கள் தகவல்களை பதிவேற்ற
              வேண்டுகிறோம். நமது தென்மாவட்ட ஒக்கலிகர் (காப்பு)மகாஜன நல சங்கம்
              மாநில சங்கத்தில் அங்கம் வகிக்கும் பொருட்டு தீவிர முயற்சி எடுத்து
              வருகின்றனர் நமது நிர்வாகிகள்.
            </p>
            <p>வாழ்க வளமுடன் வளர்க ஒக்கலிகர் சமுதாயம்.</p>
          </div>
        </div>
      </div>
      <div className="member-head">
        <div className="member-head1">
          <h1>Our Historical Leaders</h1>
        </div>
      </div>
      <div className="fillpage">
        <div className="gridsplit">
          <div>
            <img src={Leaderimg} alt="" className="historyleader" />
            <h3 className="leadername">Vishwaa</h3>
          </div>
          <div>
            <img src={Leaderimg} alt="" className="historyleader" />
            <h3 className="leadername">Vishwaa</h3>
          </div>
          <div>
            <img src={Leaderimg} alt="" className="historyleader" />
            <h3 className="leadername">Vishwaa</h3>
          </div>
          <div>
            <img src={Leaderimg} alt="" className="historyleader" />
            <h3 className="leadername">Vishwaa</h3>
          </div>
          <div>
            <img src={Leaderimg} alt="" className="historyleader" />
            <h3 className="leadername">Vishwaa</h3>
          </div>
          <div>
            <img src={Leaderimg} alt="" className="historyleader" />
            <h3 className="leadername">Vishwaa</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
