/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../Assets/Css/Family.css";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { firebase } from "../../database/firebase";
import Modal from "react-modal";
import {
  createFamily,
  updateFamily,
  viewBymemberFamily,
} from "../../Api/family";
import { FaPlus } from "react-icons/fa";
import { viewByidMember } from "../../Api/member";
import { createNofification } from "../../Api/nofification";
import { BsFillPencilFill } from "react-icons/bs";
import moment from "moment";
import Citylist from "../../Pages/citylist.json";

const Family = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const [allfamily, setallfamily] = useState([]);
  const [mydata, setmydata] = useState([]);
  const [singlefamily, setsinglefamily] = useState([]);
  const [familyid, setfamilyid] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [formdatadata, setformdatadata] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    role: "",
    profile: "",
    description: "",
    member: "",
    dob: "",
    occupation: "",
    state: "",
    city: "",
    address: "",
    cast: "",
    kolom: "",
    kothiram: "",
    jathagam: "",
    gender: "",
  });
  const [clicked, setclicked] = useState("false");
  const [viewbtn, setviewbtn] = useState(true);
  const [updateid, setupdateid] = useState(null);
  const [statelist, setstatelist] = useState([]);
  const [citylist, setcitylist] = useState([]);

  var memberid =
    localStorage.getItem("memberid") || sessionStorage.getItem("memberid");
  useEffect(() => {
    getAlldata();
  }, [memberid]);
  const getAlldata = async () => {
    if (memberid !== null) {
      var alldata = await viewBymemberFamily({ id: memberid });
      if (alldata.length !== 0) {
        setallfamily(alldata);
        // setsinglefamily([alldata[0]]);
        // setfamilyid(alldata[0].id);
      }
      var mydata = await viewByidMember({ id: memberid });
      setmydata(mydata);
      setsinglefamily([mydata[0]]);
    }
    setstatelist(Citylist.states);
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const geturl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdatadata((values) => ({ ...values, jathagam: imgurl1 }));
      }
    }
  };
  const getprofile = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    const fileSize = e.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb >= 2) {
      toast.error("Please select a file less than 2MB.", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("course/" + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      if (imgurl1 !== null) {
        toast.success("Image uploaded...", {
          autoClose: 5000,
          transition: Slide,
        });
        setformdatadata((values) => ({ ...values, profile: imgurl1 }));
      }
    }
  };
  const save_btn = async () => {
    if (formdatadata.name.length === 0) {
      toast.error("Please Enter Name...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (formdatadata.profile.length === 0) {
      toast.error("Please Upload Profile...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      formdatadata["member"] =
        localStorage.getItem("memberid") || sessionStorage.getItem("memberid");
      var nofidata = {
        content: `${mydata[0].firstname} ${mydata[0].lastname} added a new family member.`,
        status: "true",
        validto: moment().add(7, "days").format("YYYY-MM-DD"),
      };
      var createfamily = await createFamily(formdatadata);
      if (createfamily.message === "SUCCESS") {
        toast.success("Family Registed Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        createNofification(nofidata);
        setclicked(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  };
  const [modalIsOpennew, setmodalIsOpennew] = React.useState(false);

  const changeformdta = async (e) => {
    const { name, value } = e.target;
    if (name === "state") {
      var checkdata = await Citylist.states.filter((data) => {
        return data.state == e.target.value;
      });
      setcitylist(checkdata[0].districts);
      setformdatadata((values) => ({ ...values, [name]: value }));
    }
    setformdatadata((values) => ({ ...values, [name]: value }));
  };
  const editbtn = async () => {
    setmodalIsOpennew(true);
    setformdatadata({
      name: singlefamily[0].name === null ? "" : singlefamily[0].name,
      email: singlefamily[0].email === null ? "" : singlefamily[0].email,
      password:
        singlefamily[0].password === null ? "" : singlefamily[0].password,
      phone: singlefamily[0].phone === null ? "" : singlefamily[0].phone,
      role: singlefamily[0].role === null ? "" : singlefamily[0].role,
      profile: singlefamily[0].profile === null ? "" : singlefamily[0].profile,
      description:
        singlefamily[0].description === null ? "" : singlefamily[0].description,
      member: singlefamily[0].member === null ? "" : singlefamily[0].member,
      dob: singlefamily[0].dob === null ? "" : singlefamily[0].dob,
      occupation:
        singlefamily[0].occupation === null ? "" : singlefamily[0].occupation,
      state: singlefamily[0].state === null ? "" : singlefamily[0].state,
      city: singlefamily[0].city === null ? "" : singlefamily[0].city,
      address: singlefamily[0].address === null ? "" : singlefamily[0].address,
      cast: singlefamily[0].cast === null ? "" : singlefamily[0].cast,
      kolom: singlefamily[0].kolom === null ? "" : singlefamily[0].kolom,
      kothiram:
        singlefamily[0].kothiram === null ? "" : singlefamily[0].kothiram,
      jathagam:
        singlefamily[0].jathagam === null ? "" : singlefamily[0].jathagam,
      gender: singlefamily[0].gender === null ? "" : singlefamily[0].gender,
    });

    setupdateid(singlefamily[0].id);
  };
  function closeModalnew() {
    setmodalIsOpennew(false);
  }
  const updatebtn = async () => {
    if (formdatadata.name.length === 0) {
      toast.error("Please Enter Name...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (formdatadata.dob.length === 0) {
      toast.error("Please Enter Date Of Birth...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (formdatadata.email.length === 0) {
      toast.error("Please Enter Email...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (formdatadata.phone.length === 0) {
      toast.error("Please Enter Phone...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (formdatadata.occupation.length === 0) {
      toast.error("Please Enter Occupation...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (formdatadata.description.length === 0) {
      toast.error("Please Enter Description...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (formdatadata.state.length === 0) {
      toast.error("Please Enter State...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (formdatadata.city.length === 0) {
      toast.error("Please Enter City...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else if (formdatadata.profile.length === 0) {
      toast.error("Please Upload Profile...", {
        autoClose: 5000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      formdatadata["id"] = updateid;
      var updatedata = await updateFamily(formdatadata);
      if (updatedata === "Updated Successfully") {
        toast.success("Family updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        var nofidata = {
          content: `${mydata[0].firstname} ${mydata[0].lastname} Updated family member details.`,
          status: "true",
          validto: moment().add(7, "days").format("YYYY-MM-DD"),
        };
        createNofification(nofidata);
        setclicked(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  };
  return (
    <>
      <div className="fam-div">
        <div className="fam-content">
          {singlefamily.length !== 0 ? (
            <div className="fam-image">
              <div className="fam-content1">
                <h1>
                  {singlefamily[0].name === undefined
                    ? `${singlefamily[0].firstname} ${singlefamily[0].lastname}`
                    : singlefamily[0].name}
                </h1>
                <p>{singlefamily[0].description}</p>
              </div>
              <div className="fam-con-images">
                <img src={singlefamily[0].profile} alt="" />
              </div>
              <div className="mem-table">
                <div className="profileheadfamily">
                  <h4>My Profile</h4>
                  {viewbtn == true ? null : (
                    <BsFillPencilFill className="editicon" onClick={editbtn} />
                  )}
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Role</th>
                      <td>{singlefamily[0].role}</td>
                    </tr>
                    <tr>
                      <th>D.O.B</th>
                      <td>{singlefamily[0].dob}</td>
                    </tr>
                    <tr>
                      <th>Phone</th>
                      <td>+91 {singlefamily[0].phone}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{singlefamily[0].email}</td>
                    </tr>
                    <tr>
                      <th>Occupation</th>
                      <td>{singlefamily[0].occupation}</td>
                    </tr>
                    <tr>
                      <th>State</th>
                      <td>
                        {" "}
                        {singlefamily[0].state === null
                          ? "-"
                          : singlefamily[0].state}
                      </td>
                    </tr>
                    <tr>
                      <th>City</th>
                      <td>
                        {" "}
                        {singlefamily[0].city === null
                          ? "-"
                          : singlefamily[0].city}
                      </td>
                    </tr>
                    <tr>
                      <th>Caste</th>
                      <td>
                        {" "}
                        {singlefamily[0].cast === null
                          ? "-"
                          : singlefamily[0].cast}
                      </td>
                    </tr>
                    <tr>
                      <th>Kulam</th>
                      <td>
                        {" "}
                        {singlefamily[0].kolom === null
                          ? "-"
                          : singlefamily[0].kolom}
                      </td>
                    </tr>
                    {/* <tr>
                      <th>Kothiram</th>
                      <td>
                        {singlefamily[0].kothiram === null
                          ? "-"
                          : singlefamily[0].kothiram}
                      </td>
                    </tr> */}
                    {/* <tr>
                      <th>Jathagam</th>
                      <td>
                        {singlefamily[0].jathagam !== null ? (
                          <button
                            onClick={() => {
                              download(singlefamily[0].jathagam);
                            }}
                          >
                            Download
                          </button>
                        ) : (
                          "Not Available"
                        )}
                      </td>
                    </tr> */}
                  </thead>
                </table>
              </div>
            </div>
          ) : null}

          <div className="fam-scroll">
            <div className="fam-tit">
              <h1>My Family</h1>
              <span className="addbtn" onClick={openModal}>
                Add
                <FaPlus className="addicon" />
              </span>
            </div>
            <div className="fam2-croll">
              {mydata.length !== 0
                ? mydata.map((data, index) => (
                    <div
                      className={
                        viewbtn == true
                          ? "fam1-croll fam1-croll-active"
                          : "fam1-croll"
                      }
                      key={index}
                      onClick={() => {
                        setfamilyid(data.id);
                        setsinglefamily([data]);
                        setviewbtn(true);
                      }}
                    >
                      <img src={data.profile} alt="" />
                      <div className="fam-detail">
                        <h3>
                          {data.firstname} {data.lastname}
                        </h3>
                        <p>{data.role}</p>
                      </div>
                    </div>
                  ))
                : null}
              {allfamily.length !== 0
                ? allfamily.map((data, index) => (
                    <div
                      className={
                        data.id == familyid
                          ? "fam1-croll fam1-croll-active"
                          : "fam1-croll"
                      }
                      key={index}
                      onClick={() => {
                        setfamilyid(data.id);
                        setsinglefamily([data]);
                        setviewbtn(false);
                      }}
                    >
                      <img src={data.profile} alt="" />
                      <div className="fam-detail">
                        <h3>{data.name}</h3>
                        <p>{data.role}</p>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        className="newsmodel"
      >
        <div>
          <div>
            <button className="closebtn" onClick={closeModal}>
              ✕
            </button>
            <h3 className="modelhead">Add New Family</h3>
          </div>
          <div className="divider mt-2"></div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Name"
              defaultValue={formdatadata.name}
              name="name"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="date"
              placeholder="Date Of Birth"
              defaultValue={formdatadata.dob}
              name="dob"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Email"
              defaultValue={formdatadata.email}
              name="email"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Phone"
              defaultValue={formdatadata.phone}
              name="phone"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Occupation"
              defaultValue={formdatadata.occupation}
              name="occupation"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          {/* <div className="form-control w-full mt-4 mb-2">
            <select
              defaultValue={formdatadata.occupation}
              name="occupation"
              onChange={changeformdta}
              className="input inputfamily w-full"
            >
              <option value="">Select Occupation</option>
              <option value="Manufacturers & Merchandise traders">
                Manufacturers & Merchandise traders
              </option>
              <option value="Agricultural">Agricultural</option>
              <option value="Alcohol/Beverages">Alcohol/Beverages</option>
              <option value="Animal Feed & Poultry">
                Animal Feed & Poultry
              </option>
              <option value="Automobiles">Automobiles</option>
              <option value="Builders">Builders</option>
              <option value="Hardware">Hardware</option>
              <option value="Hospital">Hospital</option>
              <option value="Clinic/Lab">Clinic/Lab</option>
              <option value="Chemicals">Chemicals</option>
              <option value="Computer Peripherals">Computer Peripherals</option>
              <option value="Consumer Electronics">Consumer Electronics</option>
              <option value="Cosmetics">Cosmetics</option>
              <option value="Electrical Equipment">Electrical Equipment</option>
              <option value="Food Products & Bakery">
                {" "}
                Food Products & Bakery
              </option>
              <option value="Furniture">Furniture</option>
              <option value="Gifts & Novelty Items">
                Gifts & Novelty Items
              </option>
              <option value="Handicrafts">Handicrafts</option>
              <option value="Industrial Electricals">
                Industrial Electricals
              </option>
              <option value="Music Systems">Music Systems</option>
              <option value="Pharmaceuticals">Pharmaceuticals</option>
              <option value="Printing">Printing</option>
              <option value="PVC&Rubber products">PVC&Rubber products</option>
              <option value="Stationaries">Stationaries</option>
              <option value="Retail Shops">Retail Shops</option>
              <option value="Textiles">Textiles</option>
              <option value="Workshop">Workshop</option>
              <option value="Advertising Agencies">
                {" "}
                Advertising Agencies
              </option>
              <option value="Ayurvedic">Ayurvedic</option>
              <option value="Banks">Banks</option>
              <option value="Building Contractors">Building Contractors</option>
              <option value="Business Consultants">Business Consultants</option>
              <option value="Computer Maintenance">Computer Maintenance</option>
              <option value="Education Consultant">Education Consultant</option>
              <option value="Courier Services">Courier Services</option>
              <option value="Drilling Rig Contractors">
                Drilling Rig Contractors
              </option>
              <option value="Electrical Contractors">
                Electrical Contractors
              </option>{" "}
              <option value="Event Organisors">Event Organisors</option>
              <option value="Finance">Finance</option>
              <option value="Hotels/Holiday Resorts">
                Hotels/Holiday Resorts
              </option>
              <option value="Manpower Recruitment">Manpower Recruitment</option>
              <option value="NGO's">NGO's</option>
              <option value="Real Estate">Real Estate</option>
              <option value="Software/Web Developers">
                Software/Web Developers
              </option>
              <option value="Tansporters/C&F Agents">
                Tansporters/C&F Agents
              </option>
              <option value="Training Services">Training Services</option>
              <option value="Travel Agency">Travel Agency</option>
            </select>
          </div> */}
          <div className="form-control w-full mt-4 mb-2">
            <textarea
              type="text"
              placeholder="Description"
              defaultValue={formdatadata.description}
              name="description"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <select
              name="state"
              onChange={changeformdta}
              defaultValue={formdatadata.state}
              className="input inputfamily w-full"
            >
              <option value="">State</option>
              {statelist.length !== 0
                ? statelist.map((data, index) => (
                    <option value={data.state} key={index}>
                      {data.state}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <select
              name="city"
              onChange={changeformdta}
              defaultValue={formdatadata.city}
              className="input inputfamily w-full"
            >
              <option value="">City</option>
              {citylist.length !== 0
                ? citylist.map((data, index) => (
                    <option value={data} key={index}>
                      {data}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <textarea
              type="text"
              placeholder="Address"
              defaultValue={formdatadata.address}
              name="address"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <select
              name="role"
              onChange={changeformdta}
              defaultValue={formdatadata.role}
              className="input inputfamily w-full"
            >
              <option value="">Select Role</option>
              <option value="President">President</option>
              <option value="Vice President">Vice President</option>
              <option value="Secretary">Secretary</option>
              <option value="Treasurer">Treasurer</option>
              <option value="Executive Members">Executive Members</option>
              <option value="Advisors">Advisors</option>
              <option value="Past Office Bearers">Past Office Bearers</option>
            </select>
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Caste"
              defaultValue={formdatadata.cast}
              name="cast"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Kulam"
              defaultValue={formdatadata.kolom}
              name="kolom"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          {/* <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Kothiram"
              defaultValue={formdatadata.kothiram}
              name="kothiram"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div> */}
          <div className="form-control w-full mt-4 mb-2">
            <h5 className="newlabel">Profile</h5>
            <input
              type="file"
              placeholder="Profile"
              name="jathagam"
              className="input inputfamily w-full"
              onChange={getprofile}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <h5 className="newlabel">Jathagam</h5>
            <input
              type="file"
              placeholder="Profile"
              name="jathagam"
              className="input inputfamily w-full"
              onChange={geturl}
            />
          </div>

          <div className="divider mt-2"></div>
          <div className="modal-action">
            {clicked === true ? (
              <button className="savebtn">Please Wait...</button>
            ) : (
              <button className="savebtn" onClick={save_btn}>
                Save
              </button>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpennew}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModalnew}
        style={customStyles}
        className="newsmodel"
      >
        <div>
          <div>
            <button className="closebtn" onClick={closeModalnew}>
              ✕
            </button>
            <h3 className="modelhead">Edit Family</h3>
          </div>
          <div className="divider mt-2"></div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Name"
              defaultValue={formdatadata.name}
              name="name"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="date"
              placeholder="Date Of Birth"
              defaultValue={formdatadata.dob}
              name="dob"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Email"
              defaultValue={formdatadata.email}
              name="email"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Phone"
              defaultValue={formdatadata.phone}
              name="phone"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <select
              defaultValue={formdatadata.gender}
              name="gender"
              onChange={changeformdta}
              className="input inputfamily w-full"
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <select
              name="occupation"
              onChange={changeformdta}
              defaultValue={formdatadata.occupation}
              className="input inputfamily w-full"
            >
              <option value="">Select Occupation</option>
              <option value="Manufacturers & Merchandise traders">
                Manufacturers & Merchandise traders
              </option>
              <option value="Agricultural">Agricultural</option>
              <option value="Alcohol/Beverages">Alcohol/Beverages</option>
              <option value="Animal Feed & Poultry">
                Animal Feed & Poultry
              </option>
              <option value="Automobiles">Automobiles</option>
              <option value="Builders">Builders</option>
              <option value="Hardware">Hardware</option>
              <option value="Hospital">Hospital</option>
              <option value="Clinic/Lab">Clinic/Lab</option>
              <option value="Chemicals">Chemicals</option>
              <option value="Computer Peripherals">Computer Peripherals</option>
              <option value="Consumer Electronics">Consumer Electronics</option>
              <option value="Cosmetics">Cosmetics</option>
              <option value="Electrical Equipment">Electrical Equipment</option>
              <option value="Food Products & Bakery">
                {" "}
                Food Products & Bakery
              </option>
              <option value="Furniture">Furniture</option>
              <option value="Gifts & Novelty Items">
                Gifts & Novelty Items
              </option>
              <option value="Handicrafts">Handicrafts</option>
              <option value="Industrial Electricals">
                Industrial Electricals
              </option>
              <option value="Music Systems">Music Systems</option>
              <option value="Pharmaceuticals">Pharmaceuticals</option>
              <option value="Printing">Printing</option>
              <option value="PVC&Rubber products">PVC&Rubber products</option>
              <option value="Stationaries">Stationaries</option>
              <option value="Retail Shops">Retail Shops</option>
              <option value="Textiles">Textiles</option>
              <option value="Workshop">Workshop</option>
              <option value="Advertising Agencies">
                {" "}
                Advertising Agencies
              </option>
              <option value="Ayurvedic">Ayurvedic</option>
              <option value="Banks">Banks</option>
              <option value="Building Contractors">Building Contractors</option>
              <option value="Business Consultants">Business Consultants</option>
              <option value="Computer Maintenance">Computer Maintenance</option>
              <option value="Education Consultant">Education Consultant</option>
              <option value="Courier Services">Courier Services</option>
              <option value="Drilling Rig Contractors">
                Drilling Rig Contractors
              </option>
              <option value="Electrical Contractors">
                Electrical Contractors
              </option>{" "}
              <option value="Event Organisors">Event Organisors</option>
              <option value="Finance">Finance</option>
              <option value="Hotels/Holiday Resorts">
                Hotels/Holiday Resorts
              </option>
              <option value="Manpower Recruitment">Manpower Recruitment</option>
              <option value="NGO's">NGO's</option>
              <option value="Real Estate">Real Estate</option>
              <option value="Software/Web Developers">
                Software/Web Developers
              </option>
              <option value="Tansporters/C&F Agents">
                Tansporters/C&F Agents
              </option>
              <option value="Training Services">Training Services</option>
              <option value="Travel Agency">Travel Agency</option>
            </select>
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <textarea
              type="text"
              placeholder="Description"
              defaultValue={formdatadata.description}
              name="description"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <select
              name="state"
              onChange={changeformdta}
              defaultValue={formdatadata.state}
              className="input inputfamily w-full"
            >
              <option value="">State</option>
              {statelist.length !== 0
                ? statelist.map((data, index) => (
                    <option value={data.state} key={index}>
                      {data.state}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <select
              name="city"
              onChange={changeformdta}
              defaultValue={formdatadata.city}
              className="input inputfamily w-full"
            >
              <option value="">City</option>
              {citylist.length !== 0
                ? citylist.map((data, index) => (
                    <option value={data} key={index}>
                      {data}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <textarea
              type="text"
              placeholder="Address"
              defaultValue={formdatadata.address}
              name="address"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Relation"
              defaultValue={formdatadata.role}
              name="role"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Caste"
              defaultValue={formdatadata.cast}
              name="cast"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Kulam"
              defaultValue={formdatadata.kolom}
              name="kolom"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div>
          {/* <div className="form-control w-full mt-4 mb-2">
            <input
              type="text"
              placeholder="Kothiram"
              defaultValue={formdatadata.kothiram}
              name="kothiram"
              className="input inputfamily w-full"
              onChange={changeformdta}
            />
          </div> */}
          <div className="form-control w-full mt-4 mb-2">
            <h5 className="newlabel">Profile</h5>
            <input
              type="file"
              placeholder="Profile"
              name="jathagam"
              className="input inputfamily w-full"
              onChange={getprofile}
            />
          </div>
          <div className="form-control w-full mt-4 mb-2">
            <h5 className="newlabel">Jathagam</h5>
            <input
              type="file"
              placeholder="Jathagam"
              name="jathagam"
              className="input inputfamily w-full"
              onChange={geturl}
            />
          </div>

          <div className="divider mt-2"></div>
          <div className="modal-action">
            {clicked === true ? (
              <button className="savebtn">Please Wait...</button>
            ) : (
              <button className="savebtn" onClick={updatebtn}>
                Update
              </button>
            )}
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Family;
